import { ref } from "vue"
import { auth, db } from "../firebase/config"

const useSignup = async (email, password, displayN) => {
    const signupError = ref(null)
    try {
           const res = await auth.createUserWithEmailAndPassword(email, password)
    if (res) {
        await res.user.updateProfile({ displayName: displayN })
        await db.collection('users').doc(`${email}-${res.user.uid}`).set({
            email: email,
            name: res.user.displayName,
            id: res.user.uid,
            cart: []
        })
    } else {
        signupError.value = 'Sign up fail'
    }
    } catch (err) {
        signupError.value = err.message
       console.log(err);
    }
    return signupError.value
}
export default useSignup