import { createStore } from "vuex";
import { storage } from "../firebase/config";

export default createStore({
  state: {
    topics: [
      // {
      //   title: "Baby Gift",
      //   image: 0,
      // },
      {
        title: "Baby",
        branch: "babyGifts",
        image: 1,
      },
      {
        title: "Baptism",
        branch: "baptism",
        image: 2,
      },
      {
        title: "Birthday T Shirt",
        branch: "birthdayTShirt",
        image: 3,
      },
      {
        title: "Cake Topper",
        branch: "cakeTopper",
        image: 4,
      },
      {
        title: "Candles",
        branch: "candles",
        image: 11,
      },
      {
        title: "Embroidery",
        branch: "embroidery",
        image: 5,
      },
      {
        title: "First Communion",
        branch: "firstCommunion",
        image: 12,
      },
      {
        title: "Incense Burner",
        branch: "incenseBurner",
        image: 7,
      },
      {
        title: "Boxes",
        branch: "boxes",
        image: 8,
      },
      {
        title: "Rosaries",
        branch: "rosaries",
        image: 9,
      },
      {
        title: "Souvenir",
        branch: "souvenir",
        image: 6,
      },
      {
        title: "T Shirt",
        branch: "tShirt",
        image: 10,
      },
    ],
    buyNow: [
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0001",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Adult T-shirt",
        price: "100",
        code: "0002",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Kid T-shirt",
        price: "100",
        code: "0003",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Cack Topper 1",
        price: "100",
        code: "0004",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Cack Topper 2",
        price: "100",
        code: "0005",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Bible verse T-shirt",
        price: "100",
        code: "0006",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Father's day",
        price: "100",
        code: "0007",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Mother's day",
        price: "100",
        code: "0008",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Quote T-shirt",
        price: "100",
        code: "0009",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "extra 1",
        price: "100",
        code: "0010",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "extra 2",
        price: "100",
        code: "0011",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "extra 3",
        price: "100",
        code: "0012",
        desc: "we will provide a high quality with low price",
      },
    ],
    customize: [
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0013",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0014",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0015",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0016",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0017",
        desc: "we will provide a high quality with low price",
      },
      {
        image: null,
        title: "Birthday T-shirt",
        price: "100",
        code: "0018",
        desc: "we will provide a high quality with low price",
      },
    ],
    contacts: {
      mobile: "6475041588",
      email: "agape4acc@gmail.com",
      facebook: "https://www.facebook.com/rita.younan.902",
      instagram: "https://www.instagram.com/agape4acc/",
    },
  },
  mutations: {
    async fullCoveragePics(state) {
      const imagesListOb = await storage.ref("fullCoverage").list();
      const imagesList = imagesListOb.items;
      imagesList.map(async (img, i) => {
        state.fullCoverage[i].image = await img.getDownloadURL();
      });
    },
    async buyNowPics(state) {
      const imagesOb = await storage.ref("buyNow").list();
      const imagesList = imagesOb.items;
      imagesList.forEach(async (image, i) => {
        state.buyNow[i].image = await image.getDownloadURL();
      });
    },
    async customPics(state) {
      const listOb = await storage.ref("customize").list();
      const list = listOb.items;
      list.forEach(async (image, i) => {
        state.customize[i].image = await image.getDownloadURL();
      });
    },
  },
  actions: {
    fullCoveragePicsA({ commit }) {
      commit("fullCoveragePics");
    },
    buyNowPicsA({ commit }) {
      commit("buyNowPics");
    },
    customPicsA({ commit }) {
      commit("customPics");
    },
  },
  modules: {},
});
