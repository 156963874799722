import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Gallery from "../views/Gallery";
import AboutUs from "../views/AboutUs";
import ContactUs from "../views/ContactUs.vue";
import Photo from "../views/Photo.vue";
import Developer from "../views/developer.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: Gallery,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/photo/:singleImage",
    name: "photo",
    component: Photo,
    props: true,
  },
  {
    path: "/customize/:branch",
    name: "customize",
    component: () => import("../views/customize.vue"),
    props: true,
  },
  {
    path: "/customizeSingle/:branch",
    name: "singleCustom",
    component: () => import("../views/singleCustom.vue"),
    props: true,
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/Cart.vue"),
    props: true,
  },
  {
    path: "/check-order",
    name: "checkOrder",
    component: () => import("../views/checkOrder.vue"),
    props: true,
  },
  {
    path: "/coming-soon",
    name: "comingSoon",
    component: () => import("../views/comingSoon.vue"),
    props: true,
  },
  {
    path: "/developer",
    name: "developer",
    component: () => import("../views/developer.vue")
  },
  {
    path: "/:match(.*)",
    name: "404",
    component: Developer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
