<template>
  <div>
    <h1 class="title">Contact Us</h1>
    <!-- <label class="description"
      >Mobile <i class="fas fa-phone-square-alt"></i> :
    </label>
    <a class="contact" :href="'tel: ' + contacts.mobile" target="_blank"
      >6475041588</a
    >
    <hr class="dots dots-contact" /> -->
    <label class="description">Email <i class="fas fa-envelope"></i> : </label>
    <a
      class="contact"
      :href="'mailto: ' + contacts.email + '?subject=General Inquery'"
      target="_blank"
      >{{contacts.email}}</a
    >
    <hr class="dots dots-contact" />
    <label class="description"
      >Facebook <i class="fab fa-facebook-square"></i> :
    </label>
    <a class="contact" :href="contacts.facebook" target="_blank">Agape</a>
    <hr class="dots dots-contact" />
    <label class="description"
      >Instagram <i class="fab fa-instagram-square"></i> :
    </label>
    <a class="contact" :href="contacts.instagram" target="_blank">Agape</a>
    <hr class="dots dots-contact" />
  </div>
</template>

<script>
import { ref } from "vue";
import store from "../store";
export default {
  setup() {
    const contacts = ref(store.state.contacts);
    return { contacts };
  },
};
</script>

<style scoped>
.contact {
  margin-left: 30px;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
}
.dots-contact {
  margin: 50px auto;
}
</style>