const { ref } = require("vue")
import { storage } from "../firebase/config";

const getPhotos = async (route) => {
  const images = ref([])
  try {
    const imagesListOb = await storage.ref(route).list()
    const imageList = imagesListOb.items
    imageList.forEach(async (image, i) => {
      const res = await image.getDownloadURL()
      images.value.push({
        img: res,
        alt: "NotFound"
      })
    })
  } catch (error) {
    console.log(error.message);
  }
  return images.value
}
export default getPhotos