<template>
  <div>
    <button @click="addToDatabase">Add to Database</button>
  </div>
</template>

<script>
import { ref } from "vue";
import { db, storage } from "../firebase/config";

export default {
  setup() {
    const addToDatabase = async () => {
      const allBranches = await storage.ref(`branches`).listAll();
      await Promise.all(
        allBranches.prefixes.map(async (branch, i) => {
          const items = await storage.ref(`branches/${branch.name}`).listAll();
          items.items.forEach(async (item) => {
            await db
              .collection("branches")
              .doc(branch.name)
              .collection("items")
              .add({
                name: item.name,
                downloadURL: await item.getDownloadURL(),
                path: item.fullPath,
                price: "",
                description: "",
                note: "",
                color: "",
                sizes: "",
                available: true,
              });
          });
          console.log(i);
        })
      );
      console.log("Done");
    };
    return { addToDatabase };
  },
};
</script>

<style scoped></style>
