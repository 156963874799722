<template>
  <div>
    <Heading />
    <router-view />
    <!-- <div class="const">
      <h1>Our Website is under Maintenance</h1>
      <h2>Sorry for that</h2>
      <h2>See You soon...</h2>
    </div> -->
  </div>
</template>
<script>
import Heading from "./components/Heading.vue";
export default {
  components: { Heading },
  setup() {
    return {};
  },
};
</script>
