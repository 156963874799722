import { ref } from "@vue/reactivity"
import { auth } from "../firebase/config"

const useLogout = async () => {
    const outError = ref(null)
    try {
        await auth.signOut()
    } catch (err) {
        outError.value = err.message
    }
    return outError.value
}
export default useLogout