<template>
  <div>
    <h1 class="title">About Us</h1>
    <p class="description">
      Agape4acc.com is a collection of accessories and clothing for your child
      baptism, First communion, Baby shower, gift sets. Celebrate your child
      occasion with all your preferred details .
    </p>
    <p class="description">
      Our collection of baptism/christening, first communion, flower girl
      dresses those are handmade and customized as you want them to be.
    </p>
    <p class="description">
      Personalize a candle, rosery, giveaways, towels embroidery, and much more.
      we also offer shipping all over the world.
    </p>
    <p class="description">
      we are pledged to cover everything you need for your child Baptism
      including: Customized dresses,a variety of Fabrics,corsages, Ready
      dresses, Embroidery towels, Bodysuit, Shoes, Socks,acrylic Boxes, Holy
      water bottle, Passifer holders, Sets of combs, Rosaries, Bonbonieres,
      Candles and box of candles, Baptism changing Mats and much more.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
