<template>
  <!-- eslint-disable -->
  <h1 class="title">Agape Services</h1>
  <div class="first-section">
    <div>
      <p class="description">
        Agape4acc.com is a collection of accessories and clothing for your child
        baptism, First communion, Baby shower, gift sets. Celebrate your child
        occasion with all your preferred details .
      </p>
      <p class="description">
        Our collection of baptism/christening, first communion, flower girl
        dresses those are handmade and customized as you want them to be.
      </p>
      <p class="description">
        Personalize a candle, rosery, giveaways, towels embroidery, and much
        more. we also offer shipping all over the world.
      </p>
    </div>
    <div>
      <img
        src="../assets/photos/main/1.jpg"
        alt="image is not available"
        class="main-image"
      />
    </div>
  </div>
  <hr class="dots" />
  <!-- <img :src="images[0].image" alt=""> -->
  <div class="galleria">
    <Galleria
      :value="images"
      containerStyle="width: 100%; display: block; margin: auto; border: #bda555e1 10px solid; border-radius: 10px; box-shadow: 10px 10px 10px grey"
      :circular="true"
      :autoPlay="true"
      :transitionInterval="5000"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.img"
          :alt="slotProps.item.alt"
          style="width: 100%; display: block"
        />
      </template>
      <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.img"
          :alt="slotProps.item.alt"
          style="display: block; max-height: 100px"
        />
      </template>
    </Galleria>
  </div>
  <hr class="dots" />
  <h1 class="title cus" id="main">Customize Your Order Now</h1>
  <div class="card-flex">
    <Card
      v-for="(top, index) in topics"
      :key="index"
      :image="top.image"
      :title="top.title"
      @click="goToCustomize(top.branch)"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Card from "../components/Card.vue";
import store from "../store";
import getPhotos from "../modules/getPhotos";
import { useRouter } from "vue-router";
export default {
  components: { Card },
  setup() {
    const router = useRouter();
    const topics = ref(store.state.topics);
    const images = ref([]);
    const goToCustomize = (br) => {
      router.push({ name: "customize", params: { branch: br } });
    };
    onMounted(async () => {
      if (window.location.hash === "#main") {
        document.querySelector("#main").scrollIntoView();
      }
      images.value = await getPhotos("/slides");
    });
    return { topics, images, goToCustomize };
  },
};
</script>

<style>
.main-image {
  width: Auto;
  max-width: 800px;
  margin: auto;
  box-shadow: 7px 7px 12px gray;
  border-radius: 5px;
}
.first-section {
  display: flex;
  justify-content: space-around;
}
.first-section > * {
  flex: 1;
  padding: 10px;
}
.first-section > :nth-child(2) {
  text-align: center;
}
.galleria {
  width: 60%;
  margin: auto;
}
.card-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cus {
  text-align: center;
  font-size: 3rem;
}
@media (max-width: 1500px) {
  .main-image {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  .first-section {
    display: block;
  }
  .main-image {
    display: block;
    margin: auto;
    height: 400px;
  }
}
@media (max-width: 945px) {
  .galleria {
    width: 70%;
  }
}
@media (max-width: 700px) {
  .main-image {
    display: block;
    margin: auto;
    height: 320px;
  }
  .first-section {
    display: block;
  }
  .galleria {
    width: 80%;
  }
  .cus {
    font-size: 2rem;
  }
}
@media (max-width: 500px) {
  .main-image {
    width: 100%;
    height: auto;
  }
  .card-flex {
    flex-direction: column;
    width: 94%;
  }
  .galleria {
    width: 95%;
  }
}
</style>
