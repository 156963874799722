<template>
  <div class="card">
    <img :src="img" alt="not present" class="card-img" />
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { storage } from "../firebase/config";
import { ref } from "@vue/reactivity";
export default {
  props: ["image", "title"],
  setup(props) {
    const img = ref("");
    onMounted(async () => {
      const ref = storage.ref(`topics/${props.image}.jpg`);
      img.value = await ref.getDownloadURL();
    });
    return { img };
  },
};
</script>

<style scoped>
.card {
  display: inline-block;
  width: 30%;
  margin: 10px;
  margin-bottom: 50px;
  border: transparent;
  border-radius: 7px;
  text-align: center;
  text-decoration: none;
  background-color: aliceblue;
  box-shadow: 5px 5px 10px 5px rgb(112, 109, 90);
}
.card h3 {
  font-family: "Philosopher", sans-serif;
  font-size: 3rem;
  color: rgb(226, 198, 43);
}
.card:hover {
  cursor: pointer;
  border: black 1px solid;
  box-shadow: 0 0 10px 5px rgb(124, 123, 63);
}
.card-img {
  border-radius: 3px;
  width: 100%;
  height: 300px;
}
@media (max-width: 850px) {
  .card h3 {
    font-size: 2rem;
  }
  .card-img {
    height: auto;
  }
  .card {
    width: 45%;
  }
}
@media (max-width: 500px) {
  .card {
    width: 100%;
  }
}
</style>
