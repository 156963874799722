<template>
  <!-- eslint-disable -->
  <div class="head">
    <div class="p-d-flex p-jc-between p-ai-center">
      <div class="p-d-inline-flex">
        <img src="../assets/photos/main/logo.png" alt="logo" class="logo" />
        <h1 class="main">AGAPE</h1>
      </div>
      <div class="p-d-inline-flex cart" @click="goToCart">
        <p class="cart-text">{{ user }} Cart</p>
        <i
          class="pi pi-shopping-cart"
          style="font-size: 2.5rem; padding: 20px"
        ></i>
      </div>
    </div>
    <div class="p-d-flex p-jc-between">
      <h2 class="sub">Art and Decoration Services</h2>
      <div class="p-d-inline-flex" v-if="user === 'my'">
        <h2 class="sub cart" @click="signupF">Sign up</h2>
        <h2 class="sub">|</h2>
        <h2 class="sub cart" @click="loginF">Log in</h2>
      </div>
      <div v-else>
        <h2 class="sub cart" @click="signout">Sign out</h2>
      </div>
    </div>
    <div class="nav-bar">
      <ul class="nav">
        <li class="nav-item">
          <router-link :to="{ name: 'Home' }">Home</router-link>
        </li>
        <li class="nav-item">
          <a @click="wind">Order</a>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Gallery' }">Gallery</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'AboutUs' }">About Us</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'ContactUs' }">Contact Us</router-link>
        </li>
      </ul>
    </div>
  </div>
  <Dialog header="Sign up" v-model:visible="signup">
    <h5>Name</h5>
    <InputText type="text" v-model="sName" />
    <h5>Email</h5>
    <InputText type="text" v-model="sEmail" />
    <h5>
      Password <span style="color: red">(at least 6 letters or numbers)</span>
    </h5>
    <Password v-model="sPass1" :feedback="false" />
    <h5>Confirm Password</h5>
    <Password v-model="sPass2" :feedback="false" />
    <Button
      label="Register"
      class="p-button-raised p-button-warning"
      @click="register"
    />
    <p>
      already registered??
      <span class="cart" @click="loginF">Go to log in</span>
    </p>
  </Dialog>
  <Dialog header="Log in" v-model:visible="login">
    <h5>Email</h5>
    <InputText type="text" v-model="lEmail" />
    <h5>Password</h5>
    <Password v-model="lPass" :feedback="false" />
    <Button
      label="Log In"
      class="p-button-raised p-button-warning"
      @click="log"
    />
    <p>
      not registered?? <span class="cart" @click="signupF">Go to Sign up</span>
    </p>
  </Dialog>
</template>

<script>
import { ref } from "vue";
import useSignup from "../modules/useSignup";
import useLogin from "../modules/useLogin";
import useLogout from "../modules/useLogout";
import { auth } from "../firebase/config";
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const error = ref(null);
    const user = ref(null);
    const sName = ref(null);
    const sEmail = ref(null);
    const sPass1 = ref(null);
    const sPass2 = ref(null);
    const lEmail = ref(null);
    const lPass = ref(null);
    const signup = ref(false);
    const login = ref(false);
    const wind = () => {
      window.location.href = "/#main";
    };
    const signupF = () => {
      login.value = false;
      signup.value = true;
    };
    const loginF = () => {
      login.value = true;
      signup.value = false;
    };
    const register = async () => {
      if (
        sPass1.value === process.env.VUE_APP_PASS1 &&
        sPass2.value === process.env.VUE_APP_PASS2
      ) {
        sName.value = null;
        sEmail.value = null;
        sPass1.value = null;
        sPass2.value = null;
        signup.value = false;
        router.push({ name: "checkOrder", params: {
          pass1: process.env.VUE_APP_PASS1,
          pass2: process.env.VUE_APP_PASS2
        } });
      } else {
        if (sPass1.value === sPass2.value) {
          error.value = await useSignup(
            sEmail.value,
            sPass1.value,
            sName.value
          );
          if (!error.value) {
            sName.value = null;
            sEmail.value = null;
            sPass1.value = null;
            sPass2.value = null;
            signup.value = false;
            user.value = auth.currentUser.displayName;
          } else {
            window.alert("Error Occrued, Please check your Info. ");
            sPass1.value = null;
            sPass2.value = null;
          }
        } else {
          window.alert("Passwords do not match");
        }
      }
    };
    const log = async () => {
      error.value = await useLogin(lEmail.value, lPass.value);
      if (!error.value) {
        lEmail.value = null;
        lPass.value = null;
        login.value = false;
      } else {
        window.alert(
          "Username and/or Password are incorrect!!!, please try again"
        );
        lPass.value = null;
      }
    };
    const signout = async () => {
      error.value = await useLogout();
      if (!error.value) {
        router.push({ name: "Home" });
      }
    };
    auth.onAuthStateChanged(() => {
      try {
        user.value = auth.currentUser.displayName;
      } catch (err) {
        user.value = "my";
      }
    });
    const goToCart = () => {
      if (auth.currentUser === null) {
        window.alert("Please signup or login first");
      } else {
        router.push({ name: "cart" });
      }
    };
    return {
      error,
      user,
      signup,
      login,
      signupF,
      loginF,
      goToCart,
      sName,
      sEmail,
      sPass1,
      sPass2,
      lEmail,
      lPass,
      register,
      log,
      signout,
      wind,
    };
  },
};
</script>

<style scoped>
h5 {
  margin-bottom: 3px;
}
.head {
  width: 100%;
  height: 15%;
  font-family: "Josefin Sans", sans-serif;
  color: #947c06;
  padding: 5px;
  margin-bottom: 30px;
}
.logo {
  margin: 20px 10px 0 20px;
  height: 60px;
  width: 60px;
}
.sub {
  margin-left: 30px;
  margin-bottom: 0;
  font-weight: lighter;
}
.cart:hover {
  cursor: pointer;
  color: #474020;
}
.cart-text {
  font-size: 1.5rem;
}
.main {
  margin-bottom: 0;
  font-family: "Redressed", cursive;
  font-size: 3rem;
}
/* .acc {
  color: #474020;
} */
.nav-bar {
  background-color: #b18800;
  z-index: 10;
  border-radius: 3px;
}
.nav {
  display: flex;
  width: 50%;
  list-style-type: none;
}
.nav-item {
  flex-grow: 1;
  font-size: 1.2rem;
}
.nav-bar a {
  cursor: pointer;
  text-decoration: none;
  color: rgb(244, 245, 201);
}
.nav-item a:hover {
  color: rgb(71, 64, 32);
}
.p-button-raised {
  display: block;
  margin: 10px auto;
}
@media (max-width: 1000px) {
  .nav {
    width: 70%;
  }
}
@media (max-width: 770px) {
  .nav {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .sub {
    font-size: 1.1rem;
  }
  .nav-item {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  .sub {
    font-size: 0.9rem;
  }
  .nav-item {
    font-size: 0.7rem;
  }

  .cart-text {
    display: none;
  }
}
@media (max-width: 420px) {
  .sub {
    font-size: 0.8rem;
  }
  .nav {
    padding-left: 20px;
  }
  .nav-item {
    font-size: 0.6rem;
  }
}
@media (max-width: 390px) {
  .sub {
    font-size: 0.7rem;
  }
}
</style>
