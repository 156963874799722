import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from "./firebase/config";
import primevue from "primevue/config"
import "./assets/styles/global.css"

import 'primevue/resources/themes/saga-orange/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                //core css
import 'primeicons/primeicons.css'                           //icons
import 'primeflex/primeflex.css'                           //flex

import Button from "primevue/button"
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Galleria from 'primevue/galleria';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';
import FileUpload from "primevue/fileupload"

let app

const global = () => {
  app.component("Button", Button)
  app.component("Dialog", Dialog)
  app.component("InputText", InputText)
  app.component("Password", Password)
  app.component("Galleria", Galleria)
  app.component("Dropdown", Dropdown)
  app.component("Textarea", Textarea)
  app.component("InputNumber", InputNumber)
  app.component("ProgressSpinner", ProgressSpinner)
  app.component("FileUpload", FileUpload)
}
auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App).use(store).use(router).use(primevue)
    global()
    app.mount('#app')
  }
})
