<template>
  <div>
    <img :src="singleImage" alt="" @click="returnFun"/>
  </div>
</template>

<script>
import {useRouter} from "vue-router";
export default {
  props: ["singleImage"],
  setup() {
    const router = useRouter()
    const returnFun = () => {
      router.push({name: "Gallery"})
    }
    return{returnFun}
  }
};
</script>

<style scoped>
img {
  display: block;
  width: 95%;
  border-radius: 10px;
  margin: 30px auto;
}
img:hover{
  cursor: pointer;
}
</style>