import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_apiKey,
  authDomain: "agape4acc-8ef5f.firebaseapp.com",
  projectId: "agape4acc-8ef5f",
  storageBucket: "agape4acc-8ef5f.appspot.com",
  messagingSenderId: "43727458316",
  appId: "1:43727458316:web:2b9eb150900055b020de29",
  measurementId: "G-ZWLZ77G1CH"
};

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const db = firebase.firestore()
const storage = firebase.storage()

export { auth, db, storage }
