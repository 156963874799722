<template>
  <!-- eslint-disable -->
  <div class="p-d-flex p-ai-center p-jc-between p-mx-2 nav">
    <p class="p-mx-1 nav-item" @click="goto('#bab')">Baby</p>
    <p class="p-mx-1 nav-item" @click="goto('#bap')">Baptism</p>
    <p class="p-mx-1 nav-item" @click="goto('#bt')">Baptism Towel</p>
    <p class="p-mx-1 nav-item" @click="goto('#bi')">Birthday</p>
    <p class="p-mx-1 nav-item" @click="goto('#bo')">Boxes</p>
    <p class="p-mx-1 nav-item" @click="goto('#ct')">Cake Topper</p>
    <p class="p-mx-1 nav-item" @click="goto('#c')">Candle</p>
    <p class="p-mx-1 nav-item" @click="goto('#cor')">Corsages</p>
    <p class="p-mx-1 nav-item" @click="goto('#f')">Fabric</p>
    <p class="p-mx-1 nav-item" @click="goto('#ga')">Give away</p>
    <p class="p-mx-1 nav-item" @click="goto('#htv')">HTV</p>
    <p class="p-mx-1 nav-item" @click="goto('#r')">Rosary</p>
  </div>
  <div id="bab">
    <h1>Baby</h1>
    <GalleryImg :images="baby" />
    <hr />
  </div>
  <div id="bap">
    <h1>Baptism</h1>
    <GalleryImg :images="baptism" />
    <hr />
  </div>
  <div id="bT">
    <h1>Baptism Towel</h1>
    <GalleryImg :images="baptismTowel" />
    <hr />
  </div>
  <div id="bi">
    <h1>Birthday</h1>
    <GalleryImg :images="birthday" />
    <hr />
  </div>
  <div id="bo">
    <h1>Boxes</h1>
    <GalleryImg :images="boxes" />
    <hr />
  </div>
  <div id="ct">
    <h1>Cake Topper</h1>
    <GalleryImg :images="cakeTopper" />
    <hr />
  </div>
  <div id="c">
    <h1>Candle</h1>
    <GalleryImg :images="candle" />
    <hr />
  </div>
  <div id="cor">
    <h1>Corsages</h1>
    <GalleryImg :images="corsages" />
    <hr />
  </div>
  <div id="f">
    <h1>Fabric</h1>
    <GalleryImg :images="fabric" />
    <hr />
  </div>
  <div id="ga">
    <h1>Give Away</h1>
    <GalleryImg :images="giveAway" />
    <hr />
  </div>
  <div id="htv">
    <h1>HTV</h1>
    <GalleryImg :images="htv" />
    <hr />
  </div>
  <div>
    <h1 id="r">Rosary</h1>
    <GalleryImg :images="rosary" />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import GalleryImg from "../components/GalleryImg";
import getPhotos from "../modules/getPhotos";
export default {
  components: { GalleryImg },
  setup() {
    const goto = (e) => {
      document.querySelector(e).scrollIntoView();
    };
    const baby = ref([]);
    const baptism = ref([]);
    const baptismTowel = ref([]);
    const birthday = ref([]);
    const boxes = ref([]);
    const cakeTopper = ref([]);
    const candle = ref([]);
    const corsages = ref([]);
    const fabric = ref([]);
    const giveAway = ref([]);
    const htv = ref([]);
    const rosary = ref([]);
    onMounted(async () => {
      baby.value = await getPhotos("/gallery/baby");
      baptism.value = await getPhotos("/gallery/baptism");
      baptismTowel.value = await getPhotos("/gallery/baptismTowel");
      birthday.value = await getPhotos("/gallery/birthday");
      boxes.value = await getPhotos("/gallery/boxes");
      cakeTopper.value = await getPhotos("/gallery/cakeTopper");
      candle.value = await getPhotos("/gallery/candle");
      corsages.value = await getPhotos("/gallery/corsages");
      fabric.value = await getPhotos("/gallery/fabric");
      giveAway.value = await getPhotos("/gallery/giveAway");
      htv.value = await getPhotos("/gallery/htv");
      rosary.value = await getPhotos("/gallery/rosary");
    });
    return {
      baby,
      baptism,
      baptismTowel,
      birthday,
      boxes,
      cakeTopper,
      candle,
      corsages,
      fabric,
      giveAway,
      htv,
      rosary,
      goto,
    };
  },
};
</script>

<style scoped>
hr {
  width: 70%;
  margin: 35px auto;
  height: 20px;
  border: transparent 0px solid;
  border-bottom: 5px rgba(223, 220, 57, 0.562) solid;
}
h1 {
  font-family: "Philosopher", sans-serif;
  font-weight: bold;
  color: rgb(180, 113, 12);
  margin-left: 30px;
}
.nav {
  background: blanchedalmond;
  border-radius: 10px;
}
.nav-item {
  cursor: pointer;
  font-weight: bold;
  color: brown;
  font-family: "Josefin Sans", sans-serif;
}
@media (max-width: 600px) {
  .nav-item {
    font-size: 10px;
  }
}
@media (max-width: 310px) {
  .nav-item {
    font-size: 8px;
  }
}
</style>
