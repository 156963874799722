<template>
  <a>
    <img
      v-for="(image, index) in images"
      :key="index"
      :src="image.img"
      :alt="image.alt"
      @click="big(image.img)"
    />
  </a>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  props: ["images"],
  setup() {
    const router = useRouter();
    const big = (event) => {
      router.push({ name: "photo", params: { singleImage: event } });
    };
    return { big };
  },
};
</script>

<style scoped>
img {
  width: 23%;
  height: auto;
  margin: 10px;
  box-shadow: 10px 10px 20px 0 gray;
}
img:hover {
  box-shadow: 10px 10px 20px 0 rgba(255, 187, 0, 0.555);
  cursor: pointer;
}
@media (max-width: 1000px) {
  img {
    width: 30%;
  }
}
@media (max-width: 700px) {
  img {
    width: 45%;
  }
}
@media (max-width: 410px) {
  img {
    width: 95%;
  }
}
</style>