import { ref } from "vue"
import { auth } from "../firebase/config"

const useLogin = async (email, password) => {
    const logError = ref(null)
    try {
        const res = await auth.signInWithEmailAndPassword(email, password)
        if (!res) {
            logError.value = 'log in fail'
        }
    } catch (err) {
        logError.value = err.message
    }
    return logError.value
}
export default useLogin